@tailwind base;
@tailwind components;
@tailwind utilities;


.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: transparent !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: var(--mdc-dialog-container-elevation, 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0)) !important;
}
